///
/// Forty by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Tiles */

	.tiles {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		border-top: 0 !important;

		& + * {
			border-top: 0 !important;
		}

		article {
			@include vendor('align-items', 'center');
			@include vendor('display', 'flex');
			@include vendor('transition', (
				'transform 0.25s ease',
				'opacity 0.25s ease',
				'filter 1s ease',
				'-webkit-filter 1s ease'
			));
			@include padding(4em, 4em);
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			cursor: default;
			height: 40vh;
			max-height: 40em;
			min-height: 23em;
			overflow: hidden;
			position: relative;
			width: 40%;

			.image {
				display: none;
			}

			header {
				position: relative;
				z-index: 3;
			}

			h3 {
				font-size: 1.75em;

				a {
					&:hover {
						color: inherit !important;
					}
				}
			}

			.link.primary {
				border: 0;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 4;
			}

			&:before {
				@include vendor('transition', 'opacity 0.5s ease');
				bottom: 0;
				content: '';
				display: block;
				height: 100%;
				left: 0;
				opacity: 0.5;
				position: absolute;
				width: 100%;
				z-index: 2;
			}

			&:after {
				background-color: transparentize(_palette(bg), 0.75);
				content: '';
				display: block;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 1;
				border: 2px solid #000000;
				opacity: 1.0;
			}

			&:hover {
				&:before {
					opacity: 1.0;
					border: 2px solid #000000;
				}
			}

			&.is-transitioning {
				@include vendor('transform', 'scale(0.95)');
				@include vendor('filter', 'blur(0.5em)');
				opacity: 0;
			}

			&:nth-child(4n - 1),
			&:nth-child(4n - 2) {
				flex: 1 auto;
			}

			&:nth-child(6n - 5) {
				&:before {
					background-color: _palette(accent1);
				}
			}

			&:nth-child(6n - 4) {
				&:before {
					background-color: _palette(accent2);
				}
			}

			&:nth-child(6n - 3) {
				&:before {
					background-color: _palette(accent3);
				}
			}

			&:nth-child(6n - 2) {
				&:before {
					background-color: _palette(accent4);
				}
			}

			&:nth-child(6n - 1) {
				&:before {
					background-color: _palette(accent5);
				}
			}

			&:nth-child(6n) {
				&:before {
					background-color: _palette(accent6);
				}
			}
		}

		@include breakpoint(large) {
			article {
				@include padding(4em, 3em);
				height: 30vh;
				max-height: 30em;
				min-height: 20em;
			}
		}

		@include breakpoint(medium) {
			article {
				width: 50% !important;
			}
		}

		@include breakpoint(small) {
			article {
				@include padding(3em, 1.5em);
				height: 16em;
				max-height: none;
				min-height: 0;

				h3 {
					font-size: 1.5em;
				}
			}
		}

		@include breakpoint(xsmall) {
			display: block;

			article {
				height: 20em;
				width: 100% !important;
			}
		}
	}
