.post-list {
  margin-left: 0;
  list-style: none;

}

.post-meta {
  font-size: 90%;
  font-style: italic;
  color: #606060;
}

.post-link {
  font-size: 200%;
  font-style: bold;
  a {
    text-decoration: none;
  }
}

.post-header {
  margin-bottom: -10px;
}
